import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = () => {
  return createStyles({
    root: {
      padding: 0,
    },
  });
};

const Links = ({ classes, children }) => {
  return <ul className={classes.root}>{children}</ul>;
};

export default withStyles(styles)(Links);
