import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import Section from "../Section";
import Links from "../Links";
import InfoSection from "./InfoSection";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "10.375rem",
      [theme.breakpoints.up("xl")]: {
        flexBasis: "17rem",
      },
    },
  });
};

const ContactUs = ({ classes }) => {
  return (
    <Section title="Contact Us" classes={{ root: classes.root }}>
      <Links>
        <InfoSection title="Miracu Lean">
          <Typography>
            <Typography>Barangay Hiwacloy, Goa 4422,</Typography>
            <Typography>Camarines Sur, Philippines</Typography>
            <Typography>Registered No: 05516419</Typography>
          </Typography>
        </InfoSection>
        <InfoSection title="Email">
          <Typography>info@richberry.ph</Typography>
        </InfoSection>
        <InfoSection title="Phone Number">
          <Typography>PH +63 956 003 5650</Typography>
          <Typography>USA +1 323 312 5169</Typography>
          <Typography>CA +1 647 424 2289</Typography>
        </InfoSection>
      </Links>
    </Section>
  );
};

export default withStyles(styles)(ContactUs);
