import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";
import { Link } from "gatsby";

const styles = (theme) => {
  const md = theme.breakpoints.up("md");
  return createStyles({
    root: {
      listStyle: "none",
      "& + &": {
        marginTop: "0.625rem",
        [md]: {
          marginTop: "1.4375rem",
        },
      },
    },
    link: {
      textDecoration: "none",
    },
    button: {
      fontSize: "0.6875rem",
      fontWeight: 600,
      padding: 0,
      justifyContent: "flex-start",
      lineHeight: "1.0625rem",
      textAlign: "left",
      minWidth: 0,
      [md]: {
        fontSize: "1.125rem",
        lineHeight: "1.6875rem",
      },
    },
  });
};

const Item = ({ classes, href, ...other }) => {
  return (
    <li className={classes.root}>
      <Link to={href} className={classes.link}>
        <Button className={classes.button} {...other}></Button>
      </Link>
    </li>
  );
};

export default withStyles(styles)(Item);
