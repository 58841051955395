import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Title from "./Title";

const styles = (theme) => {
  return createStyles({
    root: {
      "& p": {
        fontWeight: "normal",
        fontSize: "0.6875rem",
        [theme.breakpoints.up("md")]: {
          fontSize: "1.125rem",
          lineHeight: "2.1875rem",
        },
      },
      "& + &": {
        marginTop: "0.9375rem",
      },
    },
  });
};

const InfoSection = ({ classes, title, children }) => {
  return (
    <div className={classes.root}>
      <Title>{title}</Title>
      {children}
    </div>
  );
};

export default withStyles(styles)(InfoSection);
