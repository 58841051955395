import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Main from "./Main";
import Legal from "./Legal";

const styles = () => {
  return createStyles({});
};

const Footer = ({ classes }) => {
  return (
    <footer>
      <Main />
      <Legal />
    </footer>
  );
};

export default withStyles(styles)(Footer);
