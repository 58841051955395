import React from "react";
import { createStyles, withStyles, Grid } from "@material-ui/core";

import Container from "../../../../components/Container";
import AboutUs from "./AboutUs";
import Help from "./Help";
import ContactUs from "./ContactUs";
import Newsletter from "./Newsletter";

const styles = (theme) => {
  return createStyles({
    root: {
      backgroundColor: "#FAFAFA",
      padding: "2.5rem 0",
      [theme.breakpoints.up("lg")]: {
        paddingTop: "8.25rem",
        paddingBottom: "7.625rem",
      },
    },
  });
};

const Main = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Container>
        <Grid container justify="space-between">
          <AboutUs />
          <Help />
          <ContactUs />
          <Newsletter />
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Main);
