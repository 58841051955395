import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      [theme.breakpoints.up("sm")]: {
        width: "auto",
        flexBasis: "30%",
      },
      [theme.breakpoints.up("lg")]: {
        flexBasis: "23%",
      },
    },
    title: {
      marginBottom: "0.75rem",
      fontWeight: "bold",
      fontSize: "0.8125rem",
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.25rem",
        marginBottom: "1.9375rem",
      },
    },
  });
};

const Section = ({ classes, title, children, ...other }) => {
  return (
    <div className={classes.root} {...other}>
      <Typography className={classes.title} variant="h2">
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default withStyles(styles)(Section);
