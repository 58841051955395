import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "0.9375rem",
      [theme.breakpoints.up("xl")]: {
        width: "1.1875rem",
      },
    },
  });
};

function SvgTiktokIcon({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.991 17.255"
      className={classes.root}
    >
      <path
        d="M7.873.014C8.815 0 9.749.007 10.684 0a4.478 4.478 0 001.258 3 5.07 5.07 0 003.049 1.285v2.9a7.7 7.7 0 01-3.02-.7 8.883 8.883 0 01-1.165-.669c-.007 2.1.007 4.2-.014 6.291a5.491 5.491 0 01-.971 2.833 5.356 5.356 0 01-4.249 2.308 5.242 5.242 0 01-2.933-.741A5.422 5.422 0 01.015 12.4a13.211 13.211 0 01-.007-1.071 5.413 5.413 0 016.277-4.8c.014 1.064-.029 2.128-.029 3.192a2.467 2.467 0 00-3.149 1.524 2.852 2.852 0 00-.1 1.158 2.447 2.447 0 002.516 2.063 2.415 2.415 0 001.992-1.158 1.659 1.659 0 00.295-.762c.072-1.287.043-2.567.05-3.854.007-2.9-.007-5.788.014-8.678z"
        fill="#fff"
      />
    </svg>
  );
}

export default withStyles(styles)(SvgTiktokIcon);
