import React from "react";
import { createStyles, withStyles, TextField } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      marginRight: "1rem",
      "&::placeholder": {
        fontWeight: 500,
        color: "#7C7C7C",
        fontSize: "0.6875rem",
        [theme.breakpoints.up("md")]: {
          fontSize: "1.125rem",
        },
      },
    },
    input: {
      padding: 0,
    },
  });
};

const Input = ({ classes }) => {
  return (
    <TextField
      className={classes.root}
      placeholder="Enter Email"
      type="email"
      name="email"
      InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
    />
  );
};

export default withStyles(styles)(Input);
