import React from "react";
import { createStyles, withStyles, IconButton } from "@material-ui/core";
import { xsUp } from "../../../../constants/breakpoints";

const styles = (theme) => {
  const iconSizes = {
    default: "2rem",
    xs: "2.1875rem",
    xl: "2.8125rem",
  };
  return createStyles({
    root: {
      width: iconSizes.default,
      height: iconSizes.default,
      borderRadius: "50%",
      backgroundColor: "#393939",
      padding: 0,
      [xsUp]: {
        width: iconSizes.xs,
        height: iconSizes.xs,
      },
      [theme.breakpoints.up("xl")]: {
        width: iconSizes.xl,
        height: iconSizes.xl,
      },
      "& + &": {
        marginLeft: "0.4375rem",
      },
    },
  });
};

const Item = ({ classes, ...other }) => {
  return <IconButton classes={classes} {...other} />;
};

export default withStyles(styles)(Item);
