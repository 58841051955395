import { createMuiTheme } from "@material-ui/core";

const buttonPadding = "0.5rem 1.125rem";
const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        boxShadow: "none",
        borderRadius: 0,
        padding: buttonPadding,
        minWidth: "11.75rem",
        "&:hover": {
          boxShadow: "none",
        },
      },
      outlined: {
        padding: buttonPadding,
      },
      contained: {
        boxShadow: "none",
      },
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    background: {
      light: "#F4F4F4",
    },
    primary: {
      main: "#B54143",
      dark: "#601718",
    },
    secondary: {
      main: "#597263",
    },
  },
});

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: "0.625rem",
  lineHeight: "1.25rem",
  fontWeight: 600,
  [theme.breakpoints.up("md")]: {
    lineHeight: "2.1875rem",
    fontSize: "1.125rem",
    fontWeight: "normal",
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: "1.5625rem",
  fontWeight: 600,
  textTransform: "uppercase",
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3.75rem",
  },
};

theme.typography.button = {
  ...theme.typography.button,
  textTransform: "none",
  fontWeight: 500,
  fontSize: "0.9375rem",
};

export default theme;
