import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Input from "./Input";
import SubscribeButton from "./SubscribeButton";

const styles = (theme) => {
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "0.4063rem",
      borderBottom: "1px solid #7C7C7C",
      marginTop: "1.25rem",
      marginBottom: "0.75rem",
      [theme.breakpoints.up("lg")]: {
        marginTop: "3.9375rem",
        marginBottom: "1.5625rem",
        paddingBottom: "0.9063rem",
      },
    },
  });
};

const SubscribeForm = ({ classes }) => {
  return (
    <form>
      <div className={classes.root}>
        <Input />
        <SubscribeButton />
      </div>
    </form>
  );
};

export default withStyles(styles)(SubscribeForm);
