import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "0.8125rem",
      [theme.breakpoints.up("xl")]: {
        width: "1.0625rem",
      },
    },
  });
};

function SvgPinterestIcon({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.451 17.483"
      className={classes.root}
    >
      <path
        d="M7.146 0C3.552 0 0 2.396 0 6.274c0 2.469 1.387 3.869 2.228 3.869.347 0 .546-.967.546-1.24 0-.326-.83-1.019-.83-2.375a4.734 4.734 0 014.918-4.815c2.385 0 4.151 1.356 4.151 3.846 0 1.86-.746 5.349-3.163 5.349a1.555 1.555 0 01-1.618-1.534c0-1.324.925-2.606.925-3.972 0-2.319-3.289-1.9-3.289.9A4.115 4.115 0 004.2 8.081c-.483 2.081-1.471 5.181-1.471 7.325 0 .662.095 1.314.158 1.976.119.133.06.119.242.053 1.765-2.417 1.7-2.89 2.5-6.053a2.841 2.841 0 002.431 1.261c3.72 0 5.391-3.626 5.391-6.894C13.451 2.27 10.446 0 7.146 0z"
        fill="#fff"
      />
    </svg>
  );
}

export default withStyles(styles)(SvgPinterestIcon);
