import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";

import Icon from "./Icon";

const styles = (theme) => {
  return createStyles({
    root: {
      padding: 0,
      fontSize: "0.6875rem",
      fontWeight: 500,
      color: "#7C7C7C",
      minWidth: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: "1.125rem",
      },
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      width: "0.3125rem",
      height: "0.5625rem",
      marginLeft: "0.5625rem",
      [theme.breakpoints.up("md")]: {
        width: "0.4375rem",
        height: "0.6875rem",
      },
    },
  });
};

const SubscribeButton = ({ classes }) => {
  return (
    <Button className={classes.root}>
      <div className={classes.container}>
        Subscribe
        <Icon className={classes.icon} />
      </div>
    </Button>
  );
};

export default withStyles(styles)(SubscribeButton);
