import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "1.1875rem",
      [theme.breakpoints.up("xl")]: {
        width: "1.5rem",
      },
    },
  });
};

function SvgYoutubeIcon({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.985 13.349"
      className={classes.root}
    >
      <path
        d="M18.589 2.089A2.386 2.386 0 0016.91.4 56.375 56.375 0 009.493 0a56.376 56.376 0 00-7.417.4A2.386 2.386 0 00.397 2.089a25.025 25.025 0 00-.4 4.6 25.025 25.025 0 00.4 4.6 2.35 2.35 0 001.678 1.661 56.376 56.376 0 007.417.4 56.376 56.376 0 007.417-.4 2.35 2.35 0 001.679-1.662 25.025 25.025 0 00.4-4.6 25.025 25.025 0 00-.4-4.6zM7.55 9.511V3.865l4.962 2.823L7.55 9.511z"
        fill="#fff"
      />
    </svg>
  );
}

export default withStyles(styles)(SvgYoutubeIcon);
