import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";
import { Link } from "gatsby";

import Section from "../Section";
import SubscribeForm from "./SubscribeForm";
import Social from "../Social";
import { links } from "../../.../../../../constants/links";

const styles = (theme) => {
  return createStyles({
    root: {
      marginTop: "3.3125rem",
      [theme.breakpoints.up("md")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("xl")]: {
        flexBasis: "29.625rem",
      },
    },
    text: {
      fontWeight: 500,
      lineHeight: "1.0625rem",
      [theme.breakpoints.up("md")]: {
        lineHeight: "1.5625rem",
      },
    },
    link: {
      color: "black",
    },
    body: {
      marginBottom: "3.3125rem",
      [theme.breakpoints.up("md")]: {
        marginBottom: "3.5625rem",
      },
    },
  });
};

const Newsletter = ({ classes }) => {
  return (
    <Section title="Newsletter" classes={{ root: classes.root }}>
      <div className={classes.body}>
        <Typography className={classes.text}>
          Grab our latest no sugar added recipes, promotions and exclusive deals
          right to your inbox.
        </Typography>
        <SubscribeForm />
        <Typography className={classes.text}>
          By subscribing, you agree with the{" "}
          <Link to={links.privacyPolicy} className={classes.link}>
            Privacy Policy.
          </Link>
        </Typography>
      </div>
      <Social />
    </Section>
  );
};

export default withStyles(styles)(Newsletter);
