import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Section from "./Section";
import Links from "./Links";
import Item from "./Links/Item";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "7.6875rem",
      marginBottom: "2.3125rem",
      [theme.breakpoints.up("xl")]: {
        flexBasis: "12.625rem",
      },
    },
  });
};

const Help = ({ classes }) => {
  return (
    <Section title="Help" classes={classes}>
      <Links>
        <Item>My Account</Item>
        <Item>Returns and Refunds</Item>
        <Item>Privacy Policy</Item>
        <Item>Terms and Conditions</Item>
        <Item>Data Protection and Data Security Policy</Item>
      </Links>
    </Section>
  );
};

export default withStyles(styles)(Help);
