import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "1.125rem",
      [theme.breakpoints.up("xl")]: {
        width: "1.4375rem",
      },
    },
  });
};

function SvgInstagramIcon({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.5 17.496"
      className={classes.root}
    >
      <path
        d="M8.752 4.262a4.486 4.486 0 104.486 4.486 4.479 4.479 0 00-4.486-4.486zm0 7.4a2.916 2.916 0 112.916-2.916 2.922 2.922 0 01-2.916 2.916zm5.716-7.586a1.046 1.046 0 11-1.047-1.044 1.044 1.044 0 011.047 1.047zm2.971 1.062a5.178 5.178 0 00-1.414-3.664A5.212 5.212 0 0012.359.062C10.914-.02 6.585-.02 5.14.062a5.2 5.2 0 00-3.665 1.409A5.2 5.2 0 00.061 5.137c-.082 1.445-.082 5.774 0 7.219a5.178 5.178 0 001.414 3.665 5.219 5.219 0 003.666 1.413c1.445.082 5.774.082 7.219 0a5.178 5.178 0 003.666-1.413 5.212 5.212 0 001.413-3.666c.082-1.445.082-5.77 0-7.215zm-1.866 8.765a2.953 2.953 0 01-1.668 1.665c-1.152.457-3.885.351-5.157.351s-4.01.1-5.157-.351a2.953 2.953 0 01-1.663-1.663c-.457-1.152-.351-3.885-.351-5.157s-.1-4.01.351-5.157a2.953 2.953 0 011.666-1.664c1.152-.457 3.885-.351 5.157-.351s4.01-.1 5.157.351a2.953 2.953 0 011.663 1.663c.457 1.152.351 3.885.351 5.157s.107 4.015-.35 5.158z"
        fill="#fff"
      />
    </svg>
  );
}

export default withStyles(styles)(SvgInstagramIcon);
