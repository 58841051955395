import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      fontSize: "0.6875rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.125rem",
        lineHeight: "2.1875rem",
      },
    },
  });
};

const Title = (props) => <Typography variant="h3" {...props} />;

export default withStyles(styles)(Title);
