import { createStyles, withStyles, Box } from "@material-ui/core";

export const paddingX = "1.875rem";

const styles = () => {
  return createStyles({
    root: {
      padding: `0 ${paddingX}`,
    },
  });
};

export default withStyles(styles)(Box);
