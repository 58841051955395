import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Section from "../Section";
import Item from "./Item";
import FacebookIcon from "./FacebookIcon";
import InstagramIcon from "./InstagramIcon";
import TwitterIcon from "./TwitterIcon";
import PinterestIcon from "./PinterestIcon";
import TikTokIcon from "./TiktokIcon";
import YoutubeIcon from "./YoutubeIcon";
import LinkedInIcon from "./LinkedinIcon";

const styles = () => {
  return createStyles({
    container: {
      display: "flex",
    },
  });
};

const Social = ({ classes }) => {
  return (
    <Section title="Follow Us">
      <div className={classes.container}>
        <Item>
          <FacebookIcon />
        </Item>
        <Item>
          <InstagramIcon />
        </Item>
        <Item>
          <LinkedInIcon />
        </Item>
        <Item>
          <TwitterIcon />
        </Item>
        <Item>
          <PinterestIcon />
        </Item>
        <Item>
          <YoutubeIcon />
        </Item>
        <Item>
          <TikTokIcon />
        </Item>
      </div>
    </Section>
  );
};

export default withStyles(styles)(Social);
