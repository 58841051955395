import * as React from "react";

function SvgIconionicIosArrowForward(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.203 9.1" {...props}>
      <path
        d="M3.635 4.549L.191 1.104a.648.648 0 010-.918.656.656 0 01.921 0l3.9 3.9a.649.649 0 01.019.9L1.113 8.912a.65.65 0 11-.921-.918z"
        fill="#7c7c7c"
      />
    </svg>
  );
}

export default SvgIconionicIosArrowForward;
