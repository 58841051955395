import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "0.5625rem",
      [theme.breakpoints.up("xl")]: {
        width: "0.75rem",
      },
    },
  });
};

function SvgFacebookIcon({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.242 17.255"
      className={classes.root}
    >
      <path
        d="M8.637 9.706l.479-3.123h-3V4.557A1.561 1.561 0 017.88 2.87h1.362V.211A16.612 16.612 0 006.824 0C4.356 0 2.743 1.5 2.743 4.2v2.38H0v3.126h2.743v7.549h3.376V9.706z"
        fill="#fff"
      />
    </svg>
  );
}

export default withStyles(styles)(SvgFacebookIcon);
