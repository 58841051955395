import React from "react";
import { ThemeProvider } from "@material-ui/core";

import Layout from "./components/Layout";
import theme from "./theme";

export const wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>{element}</Layout>
    </ThemeProvider>
  );
};
