import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Section from "./Section";
import Links from "./Links";
import Item from "./Links/Item";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "6.625rem",
      [theme.breakpoints.up("xl")]: {
        flexBasis: "10.875rem",
      },
    },
  });
};

const AboutUs = ({ classes }) => {
  return (
    <Section title="About Us" classes={{ root: classes.root }}>
      <Links>
        <Item>Our Store</Item>
        <Item>FAQ</Item>
        <Item>Certificate</Item>
        <Item>Scientific Research</Item>
      </Links>
    </Section>
  );
};

export default withStyles(styles)(AboutUs);
