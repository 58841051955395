import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "0.9563rem",
      [theme.breakpoints.up("xl")]: {
        width: "1.25rem",
      },
    },
  });
};

function SvgLinkedinIcon({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.338 15.337"
      className={classes.root}
    >
      <path
        d="M3.433 15.337H.253V5.099h3.18zM1.842 3.699a1.85 1.85 0 111.841-1.858 1.857 1.857 0 01-1.841 1.858zm13.492 11.638h-3.173v-4.985c0-1.188-.024-2.711-1.653-2.711-1.653 0-1.908 1.29-1.908 2.626v5.07H5.425V5.099h3.05v1.4h.045a3.341 3.341 0 013.009-1.654c3.218 0 3.81 2.119 3.81 4.872v5.625z"
        fill="#fff"
      />
    </svg>
  );
}

export default withStyles(styles)(SvgLinkedinIcon);
