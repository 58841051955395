import { createStyles, withStyles } from "@material-ui/core";

import DescriptionSection from "./DescriptionSection";

const styles = (theme) => {
  const marginX = "auto";
  return createStyles({
    root: {
      marginLeft: marginX,
      marginRight: marginX,
      [theme.breakpoints.up("xl")]: {
        maxWidth: "105.375rem",
      },
    },
  });
};

export default withStyles(styles)(DescriptionSection);
