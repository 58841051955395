import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      width: "1.125rem",
      [theme.breakpoints.up("xl")]: {
        width: "1.4375rem",
      },
    },
  });
};

function SvgTwitterIcon({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.622 14.313"
      className={classes.root}
    >
      <path
        d="M15.811 3.567c.011.157.011.313.011.47A10.206 10.206 0 015.546 14.312 10.206 10.206 0 010 12.691a7.472 7.472 0 00.872.045 7.233 7.233 0 004.484-1.543 3.618 3.618 0 01-3.377-2.5 4.555 4.555 0 00.682.056 3.82 3.82 0 00.951-.13 3.612 3.612 0 01-2.9-3.545v-.042a3.637 3.637 0 001.633.458A3.617 3.617 0 011.23.659a10.266 10.266 0 007.447 3.78 4.077 4.077 0 01-.089-.827 3.615 3.615 0 016.251-2.471 7.111 7.111 0 002.292-.872 3.6 3.6 0 01-1.588 1.99 7.24 7.24 0 002.08-.559 7.764 7.764 0 01-1.811 1.867z"
        fill="#fff"
      />
    </svg>
  );
}

export default withStyles(styles)(SvgTwitterIcon);
