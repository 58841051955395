import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.light,
      padding: "1.125rem 0",
      [theme.breakpoints.up("lg")]: {
        padding: "4.3125rem 0",
      },
    },
    text: {
      fontWeight: 500,
      textAlign: "center",
    },
  });
};

const Legal = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        All rights reserved @richberry.ph
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Legal);
